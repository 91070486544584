import { graphql, useStaticQuery } from 'gatsby';

export const useFounders = () => {
  const {
    allMarkdownRemark: { edges: founders },
  } = useStaticQuery(
    graphql`
      query ($language: String) {
        locales: allLocale {
          edges {
            node {
              ns
              data
              language
            }
          }
        }
        allMarkdownRemark(
          filter: { fields: { langKey: { eq: $language } }, fileAbsolutePath: { regex: "//(founders)/" } }
          sort: { fields: fileAbsolutePath, order: ASC }
        ) {
          edges {
            node {
              id
              fileAbsolutePath
              frontmatter {
                name
                twitter
                featured_image {
                  childImageSharp {
                    gatsbyImageData(width: 200, placeholder: NONE, formats: [AUTO, WEBP, AVIF])
                  }
                }
              }
              html
              fields {
                langKey
              }
            }
          }
        }
      }
    `
  );
  return { founders };
};
