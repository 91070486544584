import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';

import Layout from '../components/site/Layout';
import SEO from '../components/site/SEO';
import { useFounders } from '../hooks/use-founders';

const Founder = ({ model }) => {
  const { name } = model.node.frontmatter;
  const { featured_image } = model.node.frontmatter;
  const { twitter } = model.node.frontmatter;
  const { html } = model.node;
  const imageData = getImage(featured_image);
  const twitterLink = `https://twitter.com/${twitter}`;

  return (
    <div className="founder">
      <div className="founder__image">{imageData && <GatsbyImage image={imageData} alt="" />}</div>
      <div className="founder__info">
        <h4 className="founder__info__name">{name}</h4>
        <a href={twitterLink} target="_blank" className="founder__info__twitter" rel="noreferrer">
          @{twitter}
        </a>
        <div className="founder__info__html" dangerouslySetInnerHTML={{ __html: html }}></div>
      </div>
    </div>
  );
};

export default function IndexPage(data) {
  const { t } = useTranslation();
  const { founders } = useFounders();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const scrolled = winScroll > 100 && true;
      setIsScrolled(scrolled);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Layout isScrolled={isScrolled}>
      <SEO title="NeW">
        <html lang={t('config.lang')} />
      </SEO>
      <div className="content" id="content">
        <div className="content__body">
          <div className="section hero">
            <div className="hero__inner">
              <h1 className="h1">
                Next <br /> Web <br /> Capital
              </h1>
              <div className="h1">/&nbsp;NeW</div>
            </div>
          </div>
          <section className="claim">
            <p className="h2" dangerouslySetInnerHTML={{ __html: t('home.claim.1') }}></p>
            <p className="h2" dangerouslySetInnerHTML={{ __html: t('home.claim.2') }}></p>
          </section>
          <section className="founders">
            <h2 className="h2" dangerouslySetInnerHTML={{ __html: t('home.founders.1') }}></h2>
            <h3 className="h2" dangerouslySetInnerHTML={{ __html: t('home.founders.2') }}></h3>
            <div className="founders__list">
              {founders.map((founder, index) => {
                const { langKey } = founder.node.fields;
                const { language } = data.pageContext;
                const show = langKey === language && true;
                return <React.Fragment key={index}>{show && <Founder model={founder} />}</React.Fragment>;
              })}
            </div>
          </section>
          <section className="contact">
            <h2 dangerouslySetInnerHTML={{ __html: t('home.contact.1') }}></h2>
            <ul className="h2">
              <li>
                <a href="mailto:info@nextweb.capital" target="_blank" rel="noreferrer">
                  info@nextweb.capital
                </a>
              </li>
              <li>
                <a href="https://twitter.com/NextWebCapital" target="_blank" rel="noreferrer">
                  @nextwebcapital
                </a>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
